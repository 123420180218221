// ** React Imports
import React, { lazy, } from "react"
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
import { type IntlRouteObject, } from "../types"
// ** Objects
// ** Styles
// ** Images

// lazy load all test views
const Buttons = lazy(async () => import("views/tests/Buttons"))
const Sections = lazy(async () => import("views/tests/Sections"))


const testsRoutes: IntlRouteObject[] = [
  {
    path: "tests",
    children: [
      {
        path: "buttons",
        element: <Buttons />,
        handle: {
          publicRoute: true,
        },
      },
      {
        path: "sections",
        element: <Sections />,
        handle: {
          publicRoute: true,
        },
      },

      // {
      //   path: "homes",
      //   children: [
      //     {
      //       path: "one",
      //       element: <Home />,
      //       handle: {
      //         publicRoute: true,
      //       },
      //     },
      //     {
      //       path: "two",
      //       element: <Home2 />,
      //       handle: {
      //         publicRoute: true,
      //       },
      //     },
      //     {
      //       path: "three",
      //       element: <Home3 />,
      //       handle: {
      //         publicRoute: true,
      //       },
      //     },
      //     {
      //       path: "four",
      //       element: <Home4 />,
      //       handle: {
      //         publicRoute: true,
      //       },
      //     },
      //   ],
      // },
      // {
      //   path: "about",
      //   element: <About />,
      //   handle: {
      //     publicRoute: true,
      //   },
      // },
      // {
      //   path: "hosting",
      //   children: [
      //     {
      //       path: "shared",
      //       element: <Shared />,
      //       handle: {
      //         publicRoute: true,
      //       },
      //     },
      //     {
      //       path: "wordpress",
      //       element: <Wordpress />,
      //       handle: {
      //         publicRoute: true,
      //       },
      //     },
      //     {
      //       path: "vps",
      //       element: <Vps />,
      //       handle: {
      //         publicRoute: true,
      //       },
      //     },
      //     {
      //       path: "cloud",
      //       element: <Cloud />,
      //       handle: {
      //         publicRoute: true,
      //       },
      //     },
      //     {
      //       path: "game",
      //       element: <Game />,
      //       handle: {
      //         publicRoute: true,
      //       },
      //     },
      //     {
      //       path: "reseller",
      //       element: <Reseller />,
      //       handle: {
      //         publicRoute: true,
      //       },
      //     },
      //     {
      //       path: "dedicated",
      //       element: <Dedicated />,
      //       handle: {
      //         publicRoute: true,
      //       },
      //     },
      //   ],
      // },
      // {
      //   path: "domain",
      //   element: <Domain />,
      //   handle: {
      //     publicRoute: true,
      //   },
      // },
      // {
      //   path: "blog",
      //   element: <Blog />,
      //   handle: {
      //     publicRoute: true,
      //   },
      // },
      // {
      //   path: "blog-details",
      //   element: <BlogDetails />,
      //   handle: {
      //     publicRoute: true,
      //   },
      // },
      // {
      //   path: "ssl-certificate",
      //   element: <SslCertificate />,
      //   handle: {
      //     publicRoute: true,
      //   },
      // },
      // {
      //   path: "services",
      //   element: <Services />,
      //   handle: {
      //     publicRoute: true,
      //   },
      // },
      // {
      //   path: "pricing",
      //   element: <Pricing />,
      //   handle: {
      //     publicRoute: true,
      //   },
      // },
      // {
      //   path: "compare-pricing",
      //   element: <ComparePricing />,
      //   handle: {
      //     publicRoute: true,
      //   },
      // },
      // {
      //   path: "data-center",
      //   element: <DataCenter />,
      //   handle: {
      //     publicRoute: true,
      //   },
      // },
      // {
      //   path: "login",
      //   element: <Login />,
      //   handle: {
      //     publicRoute: true,
      //   },
      // },
      // {
      //   path: "register",
      //   element: <Register />,
      //   handle: {
      //     publicRoute: true,
      //   },
      // },
      // {
      //   path: "under-construction",
      //   element: <UnderConstruction />,
      //   handle: {
      //     publicRoute: true,
      //     fullPage: true,
      //   },
      // },
      // {
      //   path: "contact",
      //   element: <Contact />,
      //   handle: {
      //     publicRoute: true,
      //   },
      // },
    ],
  },
]

export default testsRoutes
