// ** React Imports
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images


const fr = {
  app: {
    backToHome: "Retour à l'accueil",
    contactUs: "Nous contacter",
    email: "Email",
    firstAndLastName: "Prénom et Nom",
    footerCopyright: "Conception: <a>PerkTek Services</a>",
    freeCall: "Appel gratuit",
    gdpr: "RGPD",
    hostingPackTiltle: "Hébergement Professionnel + Messagerie",
    hostingPackDescription: "\
    <p>Cette formule convient à la plupart des besoins des entreprises. Elle comprend l'hébergement de votre site web sur serveur Linux et la mise en place d'une messagerie personnalisée.</p>\
    <p>\
      Cette offre est complète et ne réserve aucun frais imprévisible : il n'y a pas de majoration en fonction du trafic généré, les statistiques de fréquentation détaillées, les meilleures extensions du marché ainsi\
      que la gestion complète des adresses email de votre domaine sont offertes sans supplément. La maintenance de votre nom de domaine .com ou .fr et les frais qui y sont liés sont également compris dans la formule.\
    </p>\
    <ul>\
      <li>2 Go d'espace disque pour votre site et votre messagerie</li>\
      <li>\
        Gestion complète des adresses email du type nom@VotreDomaine.com Une interface Web vous permet de créer les comptes et de modifier les mots de passe. Vous pouvez utiliser votre logiciel de messagerie favori\
        (Outlook par exemple) ou bien consulter vos messages directement sur le Web. (nombre illimité d'adresses et jusqu'à 50 comptes POP)\
      </li>\
      <li>Un trafic illimité</li>\
      <li>La possibilité de modifier vos pages aussi souvent que vous le voulez</li>\
      <li>Les statistiques détaillées de fréquentation de votre site</li>\
      <li>Des formulaires de commande en-ligne pré-installés et personnalisables vous permettront de récupérer les coordonnées de vos prospects ou de prendre des commandes en ligne</li>\
      <li>Base de données MySQL + PHP.</li>\
      <li>Extension Microsoft FrontPage</li>\
      <li>Support technique gratuit par email et téléphone</li>\
    </ul>",
    legalNotices: "Mentions légales",
    message: "Message",
    pageNotFound: "Page introuvable",
    pageNotFoundDescription: "La page demandée n'a pas été trouvée. Vérifiez l'URL ou retournez à l'accueil.",
    phone: "Phone",
    submit: "Valider",
    toReceiveDocumentation: "Pour recevoir une documentation",
  },
  menu: {
    automation: "Automatisation",
    connectivity: "Connectivité",
    contact: "Contact",
    datacenter: "Datacenter",
    dedicatedHosting: "Hébergement dédié",
    fullRack: "Baie complète",
    highAvaliability: "Haute disponibilité",
    home: "Accueil",
    hosting: "Hébergement",
    hostingPack: "Pack hébergement",
    introduction: "Présentation",
    legalNotices: "Mentions légales",
    monitoring: "Monitoring",
    outsourcing: "Infogérance",
    partnerships: "Partnerships",
    storageCluster: "Cluster de stockage",
    theCompany: "La société",
    turnkeyServer: "Serveur clé en main",
    webdev: "WebDev",
  },
  messages: {
    apiUnavailable: "API indisponible",
    autenticationFailed: "Echec de l'autentification",
    // cantDeactivateCompanyWithContacts: "Impossible de désactiver une société avec des contacts actifs",
    // cantDeactivateCmpanyWithSubsidarie: "Impossible de désactiver une société avec des filiales",
    // cantDeactivateContactWithTodos: "Impossible de désactiver un contact avec des tâches actives",
    // cantDeactivateUserWithContact: "Impossible de désactiver un compte avec des contacts actifs",
    // cantDeactivateUserWithTeam: "Impossible de désactiver un compte avec des membres dans son équipe",
    commandTimeout: "Délai d'attente dépassée",
    dataNotValid  : "Données non valides",
    // error: "Erreur",
    // excelExportDone: "Export excel terminé",
    // existingEmail: "Email existant",
    // existingName: "Nom existant",
    // existingRegistrationID: "SIRET existant",
    // existingTitle: "Titre existant",
    // existingTrigram: "Trigramme existant",
    // existingUser: "Utilisateur existant",
    // existingValue: "Valeur existante",
    // forbidden: "Commande interdite",
    // inactiveCompany: "Société désactivée",
    // inactiveParentCompany: "Société mère désactivée",
    inactiveUser: "Utilisateur désactivé",
    // integrityError: "Erreur d'intégrité",
    internalServerError: "Erreur interne au server",
    // logoutConfirm: "Etes-vous sûr de vouloir vous déconnecter ?",
    methodNotAllowed: "Méthode non permise",
    messageSent: "Message envoyé",
    modelNotFound: "Modèle introuvable",
    // noDataToExport: "Pas de données à exporter",
    // notAllowed: "Action non permise",
    // notValidEntry: "Entrée non valide",
    // pleaseEnterValidEmail: "Merci de saisir une adresse email valide",
    // pleaseEnterPassword: "Merci de saisir votre mot de passe",
    queryException: "Erreur lors de l'execution de la requête",
    requiredEntry: "Entrée requise",
    requiredEmailOrPhone: "Email ou téléphone requis",
    // tokenBlacklisted: "Token blacklisté",
    // tokenExpired: "Token expiré",
    tokenNotValid: "Token non valide",
    unauthorized: "Non autorisé",
    unknownCommand: "Commande inconnu",
    unknownError: "Erreur inconnue",
    unknownUser: "Utilisateur inconnu",
    // userModified: "Utilisateur modifié",
    // weakPassword: "Mot de passe trop faible",
  },
  pathes: {
    hostingPack: "pack-hebergement",
  },
}

export default fr
