// ** React Imports
import React, { createContext, useContext, useState, useEffect, type ReactNode, } from "react"
// ** Store & Actions
// ** Third Party Components
import { jwtDecode, type JwtHeader, } from "jwt-decode"
// ** Custom Components
// ** Hooks, context & utils
import useLocalStorage from "hooks/useLocalStorage"
// ** Conf & helpers
import { type User, } from "conf/types/User"
// ** Styles
// ** Images


type JwtPayload = JwtHeader & { user: User }

interface AuthContextType {
  token: string | null
  setToken: React.Dispatch<React.SetStateAction<string | null>>
  connectedUser: User | null
  setConnectedUser: React.Dispatch<React.SetStateAction<User | null>>
  persist: boolean
  setPersist: React.Dispatch<React.SetStateAction<boolean>>
}

const AuthContext = createContext<AuthContextType | null>(null)

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children, }) => {

  const [ token, setToken, ] = useState<string | null>(null)
  const [ connectedUser, setConnectedUser, ] = useState<User | null>(null)
  const [ persist, setPersist, ] = useLocalStorage<boolean>("persist", false)

  useEffect(() => {
    setPersist(persist)
    localStorage.setItem("persist", JSON.stringify(persist))
  }, [ persist, ])

  useEffect(() => {
    if (token === null) return
    if (token === "") setConnectedUser(null)
    else {
      const payload = jwtDecode<JwtPayload>(token)
      if (connectedUser === null || connectedUser.id !== payload.user.id) setConnectedUser(payload.user)
    }
  }, [ token, ])

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        connectedUser,
        setConnectedUser,
        persist,
        setPersist,
      }}>
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext)
  if (context === null) {
    throw new Error("useAuth must be used within an AuthProvider context")
  }
  return context
}

export { AuthProvider, useAuth, }
