// ** React Imports
// import React from "react"
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
import type { MenuItemDescription, } from "./types/Menu"
import type { AtLeastOneElementArray, } from "./types/Helpers"
// ** Objects
// ** Styles
// ** Images

export const menuItems: AtLeastOneElementArray<MenuItemDescription> = [
  {
    label: "home",
    url: "/",
  },
  {
    label: "hosting",

    children: [
      {
        label: "hostingPack",
        url: "/hostingPack",
      },
      {
        label: "highAvaliability",
        url: "/highAvaliability",
      },
      {
        label: "webdev",
        url: "/webdev",
      },
      {
        label: "dedicatedHosting",
        url: "/dedicatedHosting",
      },
      {
        label: "turnkeyServer",
        url: "/turnkeyServer",
      },
      {
        label: "fullRack",
        url: "/fullRack",
      },
      {
        label: "storageCluster",
        url: "/storageCluster",
      },
      {
        label: "outsourcing",
        url: "/outsourcing",
      },
      {
        label: "monitoring",
        url: "/monitoring",
      },
      {
        label: "automation",
        url: "/automation",
      },
    ],
  },
  {
    label: "theCompany",
    children: [
      {
        label: "introduction",
        url: "/introduction",
      },
      {
        label: "datacenter",
        url: "/datacenter",
      },
      {
        label: "connectivity",
        url: "/connectivity",
      },
      {
        label: "partnerships",
        url: "/partnerships",
      },
      {
        label: "contact",
        url: "/contact",
      },
      {
        label: "legalNotices",
        url: "/legalNotices",
      },
    ],
  },
]
