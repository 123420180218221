// ** React Imports
import React from "react"
// ** Store & Actions
// ** Third Party Components
import { Form, } from "react-bootstrap"
import classNames from "classnames"
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images

interface ErrorProps {
  errorMessage?: string
  className?: string
}

const Error: React.FC<ErrorProps> = ({ errorMessage, className = "", }) => {
  if (errorMessage === undefined) return <></>
  return (
    <Form.Control.Feedback type="invalid" className={classNames(className)}>
      {errorMessage}
    </Form.Control.Feedback>
  )
}

export default Error
