// ** React Imports
import React, { type ReactNode, } from "react"
// import React, { type ReactNode, type CSSProperties, } from "react"
// ** Store & Actions
// ** Third Party Components
import classNames from "classnames"
// import { type DefaultValues, type FieldValues, type SubmitHandler, type SubmitErrorHandler, } from "react-hook-form"
import { type AnySchema, } from "joi"
// ** Custom Components
// ** Hooks, context & utils
import { FormProvider, useForm, Controller, type DefaultValues, type FieldValues, type SubmitHandler, type SubmitErrorHandler, } from "./FormContext"
import { useIntl, } from "utility/context/Internationalization"
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images


interface FormComponentProps<TFormValues extends FieldValues> {
  onSubmit: SubmitHandler<TFormValues>
  onError?: SubmitErrorHandler<TFormValues>
  className?: string
  children: ReactNode
}


const FormComponent = <TFormValues extends Record<string, unknown> = Record<string, unknown>>({
  children,
  onSubmit,
  onError,
  className,
  ...otherProps
}: FormComponentProps<TFormValues>): JSX.Element => {
  const { handleSubmit, } = useForm<TFormValues>()

  return (
    <form
      className={classNames(className)}
      onSubmit={handleSubmit(onSubmit, onError)}
      {...otherProps}
      noValidate
    >
      {children}
    </form>
  )
}

interface FormProps<TFormValues extends FieldValues> {
  id?: string
  name?: string
  schema?: AnySchema
  context?: object
  onSubmit: SubmitHandler<TFormValues>
  onError?: SubmitErrorHandler<TFormValues>
  defaultValues?: DefaultValues<TFormValues>
  className?: string
  // styles?: CSSProperties
  children: ReactNode
}

const Form = <TFormValues extends Record<string, unknown> = Record<string, unknown>>({
  // const Form = <TFormValues extends React.HTMLAttributes<HTMLFormElement> = React.HTMLAttributes<HTMLFormElement>>({
  schema,
  context,
  onSubmit,
  onError,
  children,
  defaultValues,
  className,
  ...otherProps
}: FormProps<TFormValues>): JSX.Element => {
  const { T, } = useIntl()

  const customMessages = {
    "any.empty": T("requiredEntry", { nameSpace: "messages", }),
    "any.only": T("notValidEntry", { nameSpace: "messages", }),
    "any.required": T("requiredEntry", { nameSpace: "messages", }),
    "date.base": T("requiredEntry", { nameSpace: "messages", }),
    "number.base": T("requiredEntry", { nameSpace: "messages", }),
    "object.base": T("requiredEntry", { nameSpace: "messages", }),
    "string.email": T("notValidEntry", { nameSpace: "messages", }),
    "string.empty": T("requiredEntry", { nameSpace: "messages", }),
    "string.max": T("notValidEntry", { nameSpace: "messages", }),
    "string.min": T("notValidEntry", { nameSpace: "messages", }),
    "string.pattern.base": T("notValidEntry", { nameSpace: "messages", }),
    "string.required": T("requiredEntry", { nameSpace: "messages", }),
  }

  return (
    <FormProvider schema={schema} context={context} defaultValues={defaultValues} customMessages={customMessages}>
      <FormComponent<TFormValues> className={classNames(className)} onSubmit={onSubmit} onError={onError} {...otherProps}>
        {children}
      </FormComponent>
    </FormProvider>
  )
}

export { Form as default, Controller, }
