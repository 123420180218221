// ** React Imports
import type { ReactNode, } from "react"
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
import type { Role, } from "conf/types/User"
import type { MenuItemDescription, GroupDescription, Badge, Cols, } from "conf/types/Menu"
// ** Styles
// ** Objects
// ** Images

export type Group = { cols: Cols, separator: boolean, bigChildren: boolean, smallChildren: boolean } & (
  | { children: MenuItem[] }
  | { component: ReactNode }
)


export class MenuItem {
  key: string
  parentKey: string | null
  isDisabled: boolean = false  
  isTitle: boolean = false
  isDivider: boolean = false
  label: string | null = null
  description: string | null = null
  icon: ReactNode | null = null
  badge: Badge | null = null
  url: string | null = null
  target: React.HTMLAttributeAnchorTarget | null = null
  fullWidth: boolean = false
  bigChildren: boolean = false
  smallChildren: boolean = false
  component: ReactNode = null
  children: MenuItem[] | Group[] | null = null

  constructor(
    item: MenuItemDescription,
    role: Role,
    keyPrefix: string,
    parentKey: string | null = null,
    index: number = 0
  ) {
    this.key = `${parentKey ?? keyPrefix}_${index}`
    this.parentKey = parentKey

    if (MenuItem.isAllowed(role, item.allowedRoles)) {
      if (item.isDisabled === true) this.isDisabled = true
      if (item.isTitle) this.isTitle = true
      if (item.isDivider) this.isDivider = true
      if (item.label !== undefined) this.label = item.label
      if (item.description !== undefined) this.description = item.description
      if (item.icon !== undefined) this.icon = item.icon
      if (item.badge !== undefined) this.badge = {
        ...item.badge,
        text: item.badge.text,
      }
      if (item.url !== undefined) this.url = item.url
      if (item.target !== undefined) this.target = item.target
      if ("fullWidth" in item && item.fullWidth === true) this.fullWidth = true
      if ("bigChildren" in item && item.bigChildren === true) this.bigChildren = true
      if ("smallChildren" in item && item.smallChildren === true) this.smallChildren = true
      if ("component" in item && item.component !== undefined) this.component = item.component
      if ("children" in item) {
        // Has sub group
        if ("cols" in item.children[0]){
          this.children = (item.children as GroupDescription[]).reduce<Group[]>((acc, group) => {
            // Permission check
            if (!MenuItem.isAllowed(role, group.allowedRoles)) return acc
            
            // Has child component
            if("component" in group) {
              return [
                ...acc,
                {
                  cols: group.cols,
                  separator: group.separator === true,
                  bigChildren: false,
                  smallChildren: false,
                  component: group.component,
                } as Group,
              ]
            }

            // Has sub items
            let gap = 0
            const groupChildren: MenuItem[] = []
            group.children.forEach(groupChils => {
              const childItem = new MenuItem(groupChils, role, keyPrefix, this.key, gap)
              if (!childItem.isNull()) {
                groupChildren.push(childItem)
                gap += groupChildren.length
              }
            })
            return [
              ...acc,
              {
                cols: group.cols,
                separator: group.separator === true,
                bigChildren: group.bigChildren === true,
                smallChildren: group.smallChildren === true,
                children: groupChildren,
              } as Group,
            ]
          }, [])
        } else {
          // Has sub items
          let index = 0
          this.children = []
          item.children.forEach(child => {
            const childItem = new MenuItem(child as MenuItemDescription, role, keyPrefix, this.key, index)
            if (!childItem.isNull()) {
              (this.children as MenuItem[]).push(childItem)
              index++
            }
          })          
        }
      }
    }
  }

  isNull(): boolean {
    return !this.isTitle
      && !this.isDivider
      && this.url === null
      && this.component === null
      && (this.children === null || this.children.length === 0)
  }

  findActiveItems(pathname: string, path: string[] = []): string[] {
    // Link item
    if (this.url === pathname) return [ ...path, this.key, ]

    if(this.hasGroupChild) {
      // Has sub group
      for (const child of this.children as Group[]) {
        if (!("component" in child)) {
          for(const subChild of child.children) {
            const activeItems = subChild.findActiveItems(pathname, [ ...path, this.key, ])
            if(activeItems.length > 0) return activeItems
          }
        }
      }
    } else if (this.children !== null) {
      // Has sub items
      for (const child of this.children as MenuItem[]) {
        const activeItems = child.findActiveItems(pathname, [ ...path, this.key, ])
        if(activeItems.length > 0) return activeItems
      }
    }
    return []
  }

  get hasGroupChild(): boolean {
    return this.children !== null && (this.children as Group[]).find(child => "cols" in child) !== undefined
  }

  get path(): string[] {
    const parts = this.key.split("_")
    const result = []

    for (let i = 2; i <= parts.length; i++) {
      result.push(parts.slice(0, i).join("_"))
    }
  
    return result
  }

  // isChildOf(key: string): boolean {
  //   return this.key.startsWith(`${key}_`)
  // }

  // // setStatus(): this {
  // setStatus(location: string): boolean {
  //   // Link item
  //   if (this.url !== null) {
  //     if (this.url === location) {
  //       console.log(this.key, this.url) // eslint-disable-line no-console
  //       this.isActive = true
  //     } else {
  //       this.isActive = false
  //     }
  //   }

  //   if(this.hasGroupChild) {
  //     for (const child of this.children as Group[]) {
  //       if (!("component" in child)) {
  //         for (const subChild of child.children) {
  //           if (subChild.setStatus(location)) {
  //             this.isActive = true
  //             this.isExpanded = true
  //           } else {
  //             this.isActive = false
  //             this.isExpanded = false
  //           }
  //         }
  //       }
  //     }
  //   } else if (this.children !== null) {
  //     for (const child of this.children as MenuItem[]) {
  //       if (child.setStatus(location)) {
  //         this.isActive = true
  //         this.isExpanded = true
  //         return true
  //       } else {
  //         this.isActive = false
  //         this.isExpanded = false
  //       }
  //     }
  //   }

  //   return this.isActive
  // }

  // resetStatus(): this {
  //   console.log("---------------------------------") // eslint-disable-line no-console
  //   console.log(this.key) // eslint-disable-line no-console
  //   this.isActive = false
  //   this.isExpanded = false
  //   this.children.forEach(child => child.resetStatus())
  //   return this
  // }

  private static isAllowed(role: Role, allowedRoles?: Role): boolean {
    if (allowedRoles === undefined) return true
    return Object.keys(allowedRoles).some(el => {
      return allowedRoles[el as keyof typeof allowedRoles] === true && role[el as keyof typeof allowedRoles]
    })
  }
}
