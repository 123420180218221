// ** React Imports
// ** Store & Actions
// ** Third Party Components
import type { ToastContainerProps, } from "react-toastify"
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
import type { MenuOptions, } from "./Menu"
// ** Objects
// ** Styles
// ** Images

export enum LayoutStyle {
  LAYOUT_STYLE_DEFAULT = "default", // eslint-disable-line no-unused-vars
  LAYOUT_STYLE_CREATIVE = "creative", // eslint-disable-line no-unused-vars
  LAYOUT_STYLE_MODERN = "modern", // eslint-disable-line no-unused-vars
}

export enum LayoutType {
  LAYOUT_VERTICAL = "vertical", // eslint-disable-line no-unused-vars
  LAYOUT_HORIZONTAL = "horizontal", // eslint-disable-line no-unused-vars
}

export enum LayoutMode {
  LAYOUT_WIDTH_FLUID = "fluid", // eslint-disable-line no-unused-vars
  LAYOUT_WIDTH_BOXED = "boxed", // eslint-disable-line no-unused-vars
  LAYOUT_WIDTH_DETACHED = "detached", // eslint-disable-line no-unused-vars
}

export enum MenuPosition {
  MENU_POSITION_SCROLLABLE = "scrollable", // eslint-disable-line no-unused-vars
  MENU_POSITION_FIXED = "fixed", // eslint-disable-line no-unused-vars
}

export enum Theme {
  THEME_LIGHT = "light", // eslint-disable-line no-unused-vars
  THEME_DARK = "dark", // eslint-disable-line no-unused-vars
}

export enum TopBarTheme {
  TOP_BAR_THEME_LIGHT = "light", // eslint-disable-line no-unused-vars
  TOP_BAR_THEME_DARK = "dark", // eslint-disable-line no-unused-vars
  TOP_BAR_THEME_BRAND = "brand", // eslint-disable-line no-unused-vars
}

export enum SideBarTheme {
  SIDE_BAR_THEME_LIGHT = "light", // eslint-disable-line no-unused-vars
  SIDE_BAR_THEME_DARK = "dark", // eslint-disable-line no-unused-vars
  SIDE_BAR_THEME_BRAND = "brand", // eslint-disable-line no-unused-vars
}

export enum SideBarSize {
  SIDE_BAR_SIZE_DEFAULT = "default", // eslint-disable-line no-unused-vars
  SIDE_BAR_SIZE_COMPACT = "compact", // eslint-disable-line no-unused-vars
  SIDE_BAR_SIZE_CONDENSED = "condensed", // eslint-disable-line no-unused-vars
  SIDE_BAR_SIZE_SM_HOVER = "sm-hover", // eslint-disable-line no-unused-vars
  SIDE_BAR_SIZE_FULL = "full", // eslint-disable-line no-unused-vars
  SIDE_BAR_SIZE_FULLSCREEN = "fullscreen", // eslint-disable-line no-unused-vars
}

export interface Animation {
  transition:
  | "bounce"
  | "flash"
  | "pulse"
  | "rubberBand"
  | "shakeX"
  | "shakeY"
  | "headShake"
  | "swing"
  | "tada"
  | "wobble"
  | "jello"
  | "heartBeat"
  | "backInDown"
  | "backInLeft"
  | "backInRight"
  | "backInUp"
  | "backOutDown"
  | "backOutLeft"
  | "backOutRight"
  | "backOutUp"
  | "bounceIn"
  | "bounceInDown"
  | "bounceInLeft"
  | "bounceInRight"
  | "bounceInUp"
  | "bounceOut"
  | "bounceOutDown"
  | "bounceOutLeft"
  | "bounceOutRight"
  | "bounceOutUp"
  | "fadeIn"
  | "fadeInDown"
  | "fadeInDownBig"
  | "fadeInLeft"
  | "fadeInLeftBig"
  | "fadeInRight"
  | "fadeInRightBig"
  | "fadeInUp"
  | "fadeInUpBig"
  | "fadeInTopLeft"
  | "fadeInTopRight"
  | "fadeInBottomLeft"
  | "fadeInBottomRight"
  | "fadeOut"
  | "fadeOutDown"
  | "fadeOutDownBig"
  | "fadeOutLeft"
  | "fadeOutLeftBig"
  | "fadeOutRight"
  | "fadeOutRightBig"
  | "fadeOutUp"
  | "fadeOutUpBig"
  | "fadeOutTopLeft"
  | "fadeOutTopRight"
  | "fadeOutBottomRight"
  | "fadeOutBottomLeft"
  | "flip"
  | "flipInX"
  | "flipInY"
  | "flipOutX"
  | "flipOutY"
  | "lightSpeedInRight"
  | "lightSpeedInLeft"
  | "lightSpeedOutRight"
  | "lightSpeedOutLeft"
  | "rotateIn"
  | "rotateInDownLeft"
  | "rotateInDownRight"
  | "rotateInUpLeft"
  | "rotateInUpRight"
  | "rotateOut"
  | "rotateOutDownLeft"
  | "rotateOutDownRight"
  | "rotateOutUpLeft"
  | "rotateOutUpRight"
  | "hinge"
  | "jackInTheBox"
  | "rollIn"
  | "rollOut"
  | "zoomIn"
  | "zoomInDown"
  | "zoomInLeft"
  | "zoomInRight"
  | "zoomInUp"
  | "zoomOut"
  | "zoomOutDown"
  | "zoomOutLeft"
  | "zoomOutRight"
  | "zoomOutUp"
  | "slideInDown"
  | "slideInLeft"
  | "slideInRight"
  | "slideInUp"
  | "slideOutDown"
  | "slideOutLeft"
  | "slideOutRight"
  | "slideOutUp"
  delay?: 2 | 3 | 4 | 5 // in seconds
  speed?: "slow" | "slower" | "fast" | "faster"
  repeat?: 1 | 2 | 3 | "infinite"
}

export interface Layout {
  layout: {
    style: LayoutStyle
    type: LayoutType
    mode: LayoutMode
    menuPosition: MenuPosition
    header: {
      type: 1 | 2 | 3 | 4
      showTopBar: boolean  
    }
    footer: {
      type: 1 | 2
      showSubscribe: boolean
      showTopBar: boolean
      showCopyright: boolean
    }
    showTopOffer: boolean
    scrollTop: boolean
    crispChat: {
      enabled: boolean
      key: string
    }
  }
  menu: MenuOptions
  theme: Theme
  topbar: {
    theme: TopBarTheme
    logo: boolean
  }
  sidebar: {
    theme: SideBarTheme
    size: SideBarSize
    user: boolean
  }
  // rightSidebar: boolean
  toastOptions: ToastContainerProps
  scrollTop: boolean
  routerTransition?: Animation
  carouselInterval: number
  gridBreakpoints: GridBreakpoints
}

export type Size = "xs" | "sm" | "md" | "lg" | "xl" | "xxl"
export type GridBreakpoints = Record<Size, number>

export type Variant = "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "dark" | "light"
