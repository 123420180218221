// ** React Imports
import React, { useEffect, } from "react"
// ** Store & Actions
// ** Third Party Components
import Icon from "@mdi/react"
import classNames from "classnames"
import { mdiEmailFastOutline, } from "@mdi/js"
import Joi from "joi"
// ** Custom Components
import Form from "components/form/Form"
import TextInput from "components/form/TextInput"
import TextAreaInput from "components/form/TextAreaInput"
import CustomButton from "components/CustomButton"
// ** Hooks, context & utils
import { useIntl, } from "utility/context/Internationalization"
import useApi from "hooks/useApi"
import { useNotification, } from "utility/context/Notification"
import { useLayout, } from "utility/context/Layout"
import { useForm, } from "components/form/FormContext"
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images

interface FormComponentProps {
  compact?: boolean
}

const FormComponent: React.FC<FormComponentProps> = ({ compact, }) => {
  const { T, } = useIntl()
  const { formState, watch, trigger, } = useForm()

  const phoneWatch = watch("phone") as string
  const emailWatch = watch("email") as string

  useEffect(() => {
    if (formState.isSubmitted) void trigger([ "phone", "email", ])
  }, [ phoneWatch, emailWatch, formState.isSubmitted, trigger, ])

  return (
    <>
      <div className={classNames("row", !compact && "mb-sm-3")}>
        <div className="col-12">
          <TextInput label={T("firstAndLastName")} type="text" name="name" autoComplete="name" />
        </div>
      </div>

      <div className={classNames("row", !compact && "mb-sm-3")}>
        <div className="col-12">
          <TextInput label={T("phone")} type="phone" name="phone" autoComplete="phone" />
        </div>
      </div>

      <div className={classNames("row", !compact && "mb-sm-3")}>
        <div className="col-12">
          <TextInput label={T("email")} type="email" name="email" autoComplete="email" />
        </div>
      </div>

      <div className={classNames("row", !compact && "mb-sm-3")}>
        <div className="col-12">
          <TextAreaInput label={T("message")} name="message" rows={4} />
        </div>
      </div>

      <div className={classNames("mb-3 mt-5 text-center", !compact && "mb-3")}>
        <CustomButton
          text={T("submit")}
          color="primary"
          hoverColor="dark"
          icon={<Icon path={mdiEmailFastOutline} size={1} />}
          type="submit"
          onClick={e => {
            e.currentTarget.blur()
          }}
        />
      </div>
    </>
  )
}

interface FormValues extends Record<string, unknown> {
  name: string
  company: string
  phone: string
  email: string
  message: string
}

interface ContactFormProps {
  title?: string
  compact?: boolean
}

const ContactForm: React.FC<ContactFormProps> = ({ title, compact = false, }) => {
  const { T, } = useIntl()
  const { toastSuccess, } = useNotification()
  const { disableLayer: { show: disableLayerShow, hide: disableLayerHide, }, } = useLayout()
  const { contactLayer: { hide: hideContactForm, }, } = useLayout()

  const storeContactCommand = useApi({
    status200: (data, errors) => {
      if (errors.length === 0) {
        toastSuccess("messageSent")
        hideContactForm()
      }
    },
  })

  const schema = Joi.object({
    name: Joi.string().required(),
    company: Joi.string().allow(""),
    phone: Joi.alternatives().conditional("email", {
      is: "",
      then: Joi.string()
        .required()
        .messages({ "string.empty": T("requiredEmailOrPhone", { nameSpace: "messages", }), }),
      otherwise: Joi.string().allow(""),
    }),
    email: Joi.alternatives().conditional("phone", {
      is: "",
      then: Joi.string()
        .required()
        .email({ tlds: { allow: false, }, })
        .messages({ "string.empty": T("requiredEmailOrPhone", { nameSpace: "messages", }), }),
      otherwise: Joi.string().allow("").email({ tlds: { allow: false, }, }),
    }),
    message: Joi.string().allow(""),
  })

  const defaultValues = {
    name: "",
    company: "",
    phone: "",
    email: "",
    message: "",
  }

  const onSubmit = (formData: FormValues, e?: React.BaseSyntheticEvent): void => {
    e?.preventDefault()
    e?.stopPropagation()
    disableLayerShow()
    storeContactCommand
      .request(
        {
          url: "/contact-request/store",
          method: "post",
          parameters: {
            name: formData.name,
            company: formData.company,
            email: formData.email,
            phone: formData.phone,
            message: formData.message,
          },
        }
      )
      .catch(() => {})
      .finally(() => {
        disableLayerHide()
      })
  }

  return (
    <>
      {title !== undefined && <h4 className="mt-4">{T(title)}</h4>}
      <Form<FormValues>
        id="contactForm"
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        schema={schema}
      >
        <FormComponent compact={compact} />
      </Form>
    </>
  )
}

export default ContactForm
