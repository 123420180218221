// ** React Imports
import React from "react"
// ** Store & Actions
// ** Third Party Components
import { Link, } from "react-router-dom"
import { Dropdown, } from "react-bootstrap"
import classNames from "classnames"
// ** Custom Components
// ** Hooks, context & utils
import useToggle from "hooks/useToggle"
import { useIntl, } from "utility/context/Internationalization"
import { useApp, } from "utility/context/App"
// ** Conf & helpers
import type { Languages, } from "conf/types/App"
// ** Objects
// ** Styles
// ** Images


const LanguageSwitcher: React.FC = () => {
  const { languages, } = useApp()
  const { language, changeLanguage, } = useIntl()

  // const [ language, setLanguage, ] = useState(intlLanguages[0])
  const [ isOpen, toggleDropdown, ] = useToggle()


  return (
    <div className="language-switcher">
      <i className="icon-globe"></i>
      <Dropdown show={isOpen} onToggle={toggleDropdown}>
        <Dropdown.Toggle
          variant="link"
          id="dropdown-languages"
          onClick={toggleDropdown}
          className="nav-link dropdown-toggle arrow-none"
        >
          <span className="align-middle d-none d-sm-inline-block">{languages[language].name}</span>
          <i className="mdi mdi-chevron-down d-none d-sm-inline-block align-middle"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu align={"end"} className="dropdown-menu-animated topbar-dropdown-menu">
          <div onClick={toggleDropdown}>
            {Object.keys(languages).map(
              (lang, i) => (
                <Link
                  to=""
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    changeLanguage(lang as Languages)
                    toggleDropdown()
                  }}
                  className={classNames("dropdown-item notify-item", language === lang && "selected")}
                  key={`${i.toString()}-lang`}
                >
                  <img
                    src={languages[lang as Languages].flag}
                    alt={languages[lang as Languages].name}
                    className="me-2"
                    height="12"
                  />
                  <span className="align-middle">{languages[lang as Languages].name}</span>
                </Link>
              )
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default LanguageSwitcher
