// ** React Imports
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images


const en = {
  app: {
    backToHome: "Back to home",
    contactUs: "Contact us",
    email: "Email",
    firstAndLastName: "First and last name",
    footerCopyright: "Development: <a>PerkTek Services</a>",
    freeCall: "Free call",
    gdpr: "GDPR",
    hostingPackTiltle: "Professional Hosting + Mail",
    hostingPackDescription: "\
    <p>This plan is suitable for most business needs. It includes hosting your website on a Linux server and a personalized messaging system.</p>\
    <p>\
      This offer is complete and does not reserve any unpredictable costs: there is no increase based on the traffic generated, detailed attendance statistics, the best extensions on the market as well as the complete management\
      of your domain's email addresses are offered at no extra cost. The maintenance of your .com or .fr domain name and the costs associated with it are also included in the package.\
    </p>\
    <ul>\
      <li>2 GB of disk space for your site and email</li>\
      <li>\
        Complete management of email addresses of the type name@YourDomain.com A Web interface allows you to create accounts and modify passwords. You can use your favorite email software (Outlook for example) or\
        consult your messages directly on the Web. (unlimited number of addresses and up to 50 POP accounts)\
      </li>\
      <li>Unlimited traffic</li>\
      <li>The ability to edit your pages as often as you want</li>\
      <li>Detailed statistics on your site traffic</li>\
      <li>Pre-installed and customizable online order forms will allow you to collect contact information from your prospects or take orders online.</li>\
      <li>MySQL database + PHP.</li>\
      <li>Microsoft FrontPage Extension</li>\
      <li>Free technical support by email and phone</li>\
    </ul>",
    legalNotices: "Legal notices",
    message: "Message",
    pageNotFound: "Page not found",
    pageNotFoundDescription: "The page you were looking for doesn't exist. Check the URL or head back home.",
    phone: "Téléphone",
    submit: "Submit",
    toReceiveDocumentation: "To receive documentation",
  },
  menu: {
    automation: "Automation",
    connectivity: "Connectivity",
    contact: "Contact",
    datacenter: "Datacenter",
    dedicatedHosting: "Dedicated hosting",
    fullRack: "Full rack",
    highAvaliability: "High availability",
    home: "Home",
    hosting: "Hosting",
    hostingPack: "Hosting pack",
    introduction: "Introduction",
    legalNotices: "Legal notices",
    monitoring: "Monitoring",
    outsourcing: "Outsourcing",
    partnerships: "Partnerships",
    storageCluster: "Storage cluster",
    theCompany: "The company",
    turnkeyServer: "Turnkey server",
    webdev: "WebDev",
  },
  messages: {
    apiUnavailable: "API unavailable",
    autenticationFailed: "Autentication failed",
    // cantDeactivateCompanyWithContacts: "Impossible de désactiver une société avec des contacts actifs",
    // cantDeactivateCmpanyWithSubsidarie: "Impossible de désactiver une société avec des filiales",
    // cantDeactivateContactWithTodos: "Impossible de désactiver un contact avec des tâches actives",
    // cantDeactivateUserWithContact: "Impossible de désactiver un compte avec des contacts actifs",
    // cantDeactivateUserWithTeam: "Impossible de désactiver un compte avec des membres dans son équipe",
    commandTimeout: "Command timeout",
    dataNotValid: "Data not valid",
    // error: "Error",
    // excelExportDone: "Excel export done",
    // existingEmail: "Email existant",
    // existingName: "Nom existant",
    // existingRegistrationID: "SIRET existant",
    // existingTitle: "Titre existant",
    // existingTrigram: "Trigramme existant",
    // existingUser: "Utilisateur existant",
    // existingValue: "Valeur existante",
    // forbidden: "Forbidden commande",
    // inactiveCompany: "Société désactivée",
    // inactiveParentCompany: "Société mère désactivée",
    inactiveUser: "Inactive user",
    // integrityError: "Erreur d'intégrité",
    internalServerError: "Internal server error",
    // logoutConfirm: "Are you sure you want to log out?",
    methodNotAllowed: "Mmethod not allowed",
    messageSent: "Message sent",
    modelNotFound: "Model not found",
    // noDataToExport: "No data to export",
    // notAllowed: "Not allowed",
    // notValidEntry: "Not valid entry",
    // pleaseEnterValidEmail: "Merci de saisir une adresse email valide",
    // pleaseEnterPassword: "Merci de saisir votre mot de passe",
    queryException: "An error occured while executing the request",
    requiredEntry: "Required entry",
    requiredEmailOrPhone: "Email or phone required",
    // tokenBlacklisted: "Token blacklisted",
    // tokenExpired: "Token expired",
    tokenNotValid: "Token not valid",
    unauthorized: "Non allowed",
    unknownCommand: "Unknown command",
    unknownError: "Unknown error",
    unknownUser: "Unknown user",
    // userModified: "User modified",
    // weakPassword: "Mot de passe trop faible",
  },
  pathes: {
    hostingPack: "hosting-pack",
  },
}

export default en
