// ** React Imports
import React, { useEffect, } from "react"
// ** Store & Actions
// ** Third Party Components
import { Link, useLocation, } from "react-router-dom"
import { FontAwesomeIcon, } from "@fortawesome/react-fontawesome"
import { faPhone, faEnvelope, faMapMarkerAlt, } from "@fortawesome/free-solid-svg-icons"
import { faXTwitter, faFacebookF, faPinterestP, faInstagram, } from "@fortawesome/free-brands-svg-icons"
import { Offcanvas, } from "react-bootstrap"
import SimpleBar from "simplebar-react"
// ** Custom Components
import LanguageSwitcher from "./LanguageSwitcher"
// ** Hooks, context & utils
import { useLayout, } from "utility/context/Layout"
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images
import Logo from "assets/images/logo.svg"


interface MobileMenuProps {
  open: boolean
  hide: () => void
  children: React.ReactNode
}

const MobileMenu: React.FC<MobileMenuProps> = ({ open, hide, children, }) => {
  const { size, } = useLayout() 
  const location = useLocation()

  useEffect(() => {
    hide()
  }, [ location, ])

  useEffect(() => {
    if(size === "xl" || size === "xxl") hide()
  }, [ size, ])

  return (
    <Offcanvas
      show={open}
      onHide={hide}
    >
      <Offcanvas.Header closeButton>
        <Link to="/" aria-label="logo image">
          <img src={Logo} height="45" alt="Axinet Logo" />
        </Link>
      </Offcanvas.Header>

      <Offcanvas.Body className="p-0 mobile-menu-wrapper">
        <SimpleBar className="h-100">
          {children}
          <ul className="mobile-nav__contact list-unstyled">
            <li>
              <FontAwesomeIcon icon={faPhone} />
              <Link to="tel:+33 805 696 606">+33 805 696 606</Link>
            </li>
            <li>
              <FontAwesomeIcon icon={faEnvelope} />
              <Link to="mailto:info@axinet.com">info@axinet.com</Link>
            </li>
            <li>
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <Link to="https://maps.app.goo.gl/JKRDdYRENerYApZbA" target="_blank">
                3 Bld des Bouvets<br />92000 Nanterre, France
              </Link>
            </li>
          </ul>

          <LanguageSwitcher />

          <ul className="mobile-nav__social">
            <li>
              <Link to="#" onClick={e => {e.preventDefault()}}>
                <FontAwesomeIcon icon={faXTwitter} size="lg" />
              </Link>
            </li>
            <li>
              <Link to="#" onClick={e => {e.preventDefault()}}>
                <FontAwesomeIcon icon={faFacebookF} size="lg" />
              </Link>
            </li>
            <li>
              <Link to="#" onClick={e => {e.preventDefault()}}>
                <FontAwesomeIcon icon={faPinterestP} size="lg" />
              </Link>
            </li>
            <li>
              <Link to="#" onClick={e => {e.preventDefault()}}>
                <FontAwesomeIcon icon={faInstagram} size="lg" />
              </Link>
            </li>
          </ul>
        </SimpleBar>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default MobileMenu
