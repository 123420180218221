// ** React Imports
import React, { useState, useEffect, } from "react"
// ** Store & Actions
// ** Third Party Components
import { Link, useLocation, } from "react-router-dom"
import classNames from "classnames"
import { Row, } from "react-bootstrap"
import { Collapse, } from "react-bootstrap"
// ** Custom Components
import Badge from "./Badge"
// ** Hooks, context & utils
import { useMenu, } from "layouts/menu/context/Menu"
// ** Conf & helpers
// ** Objects
import type { MenuItem as MenuItemObject, Group, } from "objects/menu/MenuItem"
// ** Styles
// ** Images


interface MenuItemProps extends React.HTMLProps<HTMLLIElement> {
  item: MenuItemObject
  containerRef?: React.RefObject<HTMLDivElement>
  type: "horizontal" | "vertical"
  itemClassName?: string
  linkClassName?: string
  subMenuClassName?: string
  level?: number
}

const MenuItem: React.FC<MenuItemProps> = ({ item, containerRef, type, itemClassName, linkClassName, subMenuClassName, level = 0, ...rest }) => {
  const { activeMenuItems, } = useMenu()
  const [ open, setOpen, ] = useState(type === "vertical" && activeMenuItems.includes(item.key))
  const location = useLocation()

  useEffect(() => {
    if(type === "horizontal") setOpen(false)
  }, [ location, ])

  if (item.isTitle) return <li className="menu-title">{item.label}</li>
  if (item.isDivider) return <hr className="divider" />


  return (
    <li
      id={item.key}
      className={classNames(
        "menu-item",
        itemClassName,
        {
          active: activeMenuItems.includes(item.key),
          open,
          "has-sub": item.children !== null,
          big: item.bigChildren,
          small: item.smallChildren,
          megamenu: item.component !== null,
        }
      )}
      {...(type === "horizontal" && {
        onMouseEnter: () => { setOpen(true) },
        onMouseLeave: () => { setOpen(false) },
      })}
      {...(type === "vertical" && {
        onClick: e => {
          e.stopPropagation()
          setOpen(!open)
        },
      })}
      {...rest}
    >
      <Link
        {...(item.url !== null && item.url !== "#"
          ? { to: item.url, } // eslint-disable-line no-console
          : { to: "#", onClick: e => {e.preventDefault()}, }
        )}
        className={classNames("menu-link", linkClassName)}
      >

        
        {item.icon !== undefined && (typeof item.icon === "string" ? <i className={item.icon}></i> : item.icon)}
        <span className="menu-item-label">
          {item.label}
          { item.description && <span className="menu-item-description">{item.description}</span>}
        </span>
        <Badge badge={item.badge} />
      </Link>
      {
        (item.children !== null || item.component !== null) && (
          <Collapse in={open}>
            <ul
              className={classNames(
                "sub-menu smallScrollbar",
                { "full-width": item.fullWidth, },
                subMenuClassName)
              }
              aria-labelledby={item.key}
            >
              {item.children !== null && (
                item.hasGroupChild
                  // Have groups
                  ? (
                    <Row>
                      {
                        item.children.map((group, index) => (
                          <div
                            key={`groupChild_${index}`}
                            className={classNames(`section-box col-${(group as unknown as Group).cols}`, {
                              separator: (group as unknown as Group).separator,
                              small: "small" in group && group.small,
                              big: "big" in group && group.big,
                            })}
                          >
                            {"component" in group
                              ? group.component
                              : group.children.map(child => {
                                return (
                                  <MenuItem
                                    key={child.key}
                                    item={child}
                                    containerRef={containerRef}
                                    type={type}
                                    itemClassName={itemClassName}
                                    linkClassName={linkClassName}
                                    subMenuClassName={subMenuClassName}
                                    level={level + 1}
                                  />
                                )
                              })
                            }
                          </div>
                        ))
                      }
                    </Row>
                  )
                  // Don't have groups
                  : (
                    item.children.map(child => (
                      <MenuItem
                        key={(child as MenuItemObject).key}
                        item={child as MenuItemObject}
                        containerRef={containerRef}
                        type={type}
                        itemClassName={itemClassName}
                        linkClassName={linkClassName}
                        subMenuClassName={subMenuClassName}
                        level={level + 1}
                      />
                    ))
                  )
              )}
            </ul>
          </Collapse>
        )
      }
    </li>
  )
}

export default MenuItem
