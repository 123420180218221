// ** React Imports
import React from "react"
// ** Store & Actions
// ** Third Party Components
import classNames from "classnames"
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images


interface TemplateImgProps {
  width?: number
  height?: number
  color?: string
  className?: string
}

const TemplateImg: React.FC<TemplateImgProps> = ({ width = 100, height = 100, color = "#CCCCCC", className, }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={classNames(className)} style={{ width, height, }}>
      <rect width={width} height={height} fill={color} />
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dominantBaseline="middle"
        style={{
          fontFamily: "ArialMT",
          fontSize: "12px",
        }}
      >
        {`${width}px X ${height}px`}
      </text>
    </svg>
  )
}

export default TemplateImg
