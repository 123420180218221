// ** React Imports
import React, { useState, useEffect, useRef, } from "react"
// ** Store & Actions
// ** Third Party Components
import classNames from "classnames"
import { Link, } from "react-router-dom"
// ** Custom Components
import LanguageSwitcher from "./LanguageSwitcher"
import MobileMenu from "./MobileMenu"
import Menu from "./Menu"
// ** Hooks, context & utils
import useToggle from "hooks/useToggle"
import { useLayout, } from "utility/context/Layout"
import { MenuProvider, } from "../menu/context/Menu"
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images
import Logo from "assets/images/logo.svg"

const Header: React.FC = () => {
  const { settings: { layout, }, } = useLayout()
  const [ isSticky, setIsSticky, ] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  const { type, showTopBar, } = layout.header

  useEffect(() => {
    const handleScroll = (): void => {
      const offset = window.scrollY
      if (offset > 100) {
        setIsSticky(true)
      } else {
        setIsSticky(false)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const [ mobileMenuOpen, toggleMobileMenu,, hideMobileMenu, ] = useToggle()

  return (
    <MenuProvider containerRef={containerRef}>
      <header className={classNames(
        "main-header-one",
        {
          "main-header-two": type === 2,
          "main-header-three": type === 3,
          "main-header-three main-header-four": type === 4,
          "sticky-header sticky-header-drop-down": isSticky,
        }
      )}>
        {
          showTopBar && (
            <div className={classNames("main-header-one__top", isSticky && "d-none")}>
              <div className="container">
                <div className="main-header-two__inner">
                  <div className="main-header-two__call">
                    <div className="main-header-two__call-icon">
                      <span className="icon-phone"></span>
                    </div>
                    <p className="main-header-two__call-number">
                      Helpline Call - <Link to="/tel:180090201000">(+1) 800 9020 1000</Link>
                    </p>
                  </div>
                  <div className="main-header-two__support-and-language-switcher">
                    <div className="main-header-two__support-box">
                      <div className="main-header-two__support-icon">
                        <span className="icon-support-icon"></span>
                      </div>
                      <p className="main-header-two__support-text">Help & Support</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
          )
        }
        <div
          className={classNames({
            "main-header-two__nav-box": type === 2,
            "main-header-three__nav-box sticky-header": type === 3 || type === 4,
            "sticky-header sticky-fixed": isSticky && (type === 3 || type === 4),
            "sticky-header": type === 3 || type === 4,
          })}
        >
          <div className="main-menu-container" ref={containerRef}>
            <div className="container" ref={containerRef}>
              <Link to="/">
                <img src={Logo} alt="Axinet Logo" className="main-header__logo" />
              </Link>
              <Menu type="horizontal" containerRef={containerRef} className={classNames("main-menu", (type === 3 || type === 4) && "type-2")} />
              <div className="main-header-one__right">
                <button
                  className="mobile-nav__toggler"
                  type="button"
                  onClick={toggleMobileMenu}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                    <path stroke="var(--icon-color, red)" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" d="M4 7h22M4 15h22M4 23h22"/>
                  </svg>
                </button>
                <LanguageSwitcher />
                {/*
                <Link to="#" onClick={e => {e.preventDefault()}} className="main-header-one__cart">
                  <i className="icon-cart"></i>
                </Link>
                <Link to="/login" className="thm-btn main-header-one__btn">
                  <span>
                    Login
                    <i className="icon-arrow"></i>
                  </span>
                </Link>
                */}
              </div>
            </div>
          </div>
        </div>
      </header>

      <MobileMenu
        open={mobileMenuOpen}
        hide={hideMobileMenu}
      >
        <Menu type="vertical" className="main-menu" />
      </MobileMenu>
    </MenuProvider>
  )
}

export default Header
