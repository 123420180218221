// ** React Imports
import React, { useEffect, } from "react"
// ** Store & Actions
// ** Third Party Components
import { useLocation, } from "react-router-dom"
import classNames from "classnames"
// ** Custom Components
import MenuItem from "./MenuItem"
// ** Hooks, context & utils
import { useMenu, } from "layouts/menu/context/Menu"
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images


type MenuProps = (
    | { type: "horizontal", containerRef: React.RefObject<HTMLDivElement> }
    | { type: "vertical", containerRef?: undefined }
  )
  & {
    className?: string
    itemClassName?: string
    linkClassName?: string
    subMenuClassName?: string
  }

const Menu: React.FC<MenuProps> = ({ type, containerRef, className, itemClassName, linkClassName, subMenuClassName, })=> {
  const { menu, } = useMenu()

  const location = useLocation()

  useEffect(() => {
    document.querySelectorAll(".menu-item-has-children.active").forEach(el => {
      el.classList.remove("active")
    })
  }, [ location, ])

  return (
    <nav
      className={classNames(
        type,
        className
      )}
    >
      <ul className="menu-root">
        {
          menu.children.map((item, index) => (
            <MenuItem
              key={index}
              item={item}
              containerRef={containerRef}
              type={type}
              itemClassName={itemClassName}
              linkClassName={linkClassName}
              subMenuClassName={subMenuClassName}
            />
          ))
        }
      </ul>
    </nav>
  )
}

export default Menu
