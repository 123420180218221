// ** React Imports
import React from "react"
// ** Store & Actions
// ** Third Party Components
import classNames from "classnames"
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
import type { Badge as BadgeType, } from "conf/types/Menu"
// ** Styles
// ** Images

export interface BadgeProps {
  badge: BadgeType | null
  classnames?: string
}

const Badge: React.FC<BadgeProps> = ({ badge, classnames, }) => {
  if (badge === null) return null
  return (
    <span
      className={classNames(
        classnames,
        "badge",
        `bg-${badge.variant}`,
        "rounded",
        "font-10",
        {
          "text-dark": badge.textDark,
          "bg-gradient": badge.gradient,
        }
      )}
    >
      {badge.text}
    </span>
  )
}

export default Badge
