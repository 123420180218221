// ** React Imports
import React from "react"
// ** Store & Actions
// ** Third Party Components
import { RouterProvider, } from "react-router-dom"
// import { Crisp, } from "crisp-sdk-web"
// ** Custom Components
import useRouter from "router/useRouter"
// ** Hooks, context & utils
// import { useLayout, } from "utility/context/Layout"
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images


const App: React.FC = () => {
  const { router, } = useRouter()
  // const { settings: { layout: { crispChat, }, }, } = useLayout()

  // useEffect(() => {
  //   if(crispChat.enabled) {
  //     Crisp.configure(crispChat.key)
  //   }
  // }, [])

  return <RouterProvider router={router} />
}

export default App
