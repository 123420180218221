// ** React Imports
import React, { lazy, } from "react"
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
import { type IntlRouteObject, } from "../types"
// ** Objects
// ** Styles
// ** Images

// lazy load all views
const HostingPack = lazy(async () => import("views/hosting/hostingPack/HostingPack"))
// const Contact = lazy(async () => import("views/contact/Contact"))


const templateRoutes: IntlRouteObject[] = [
  {
    path: "/",
    children: [
      {
        path: "hostingPack",
        element: <HostingPack />,
        handle: {
          publicRoute: true,
        },
      },
    ],
  },
]

export default templateRoutes
