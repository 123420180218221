// ** React Imports
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images

export const passwordReg = /^(?=\S*[\w])(?=\S*[A-Z])(?=\S*\d)(?=\S*[^\w\s])[\w\W]{8,}$/
export const fullNameReg = /^[a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ'-]+( +[a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ'-]+)+$/
// eslint-disable-next-line no-useless-escape
export const emailReg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
// eslint-disable-next-line no-useless-escape
export const phoneReg = /^0033\s?[1-9](\s?[0-9]{2}){4}$/
export const siretReg = /^[0-9]{14}$/
// export const phoneReg = /^[ +]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9 ]*$/
// eslint-disable-next-line no-useless-escape
export const phoneOrEmptyReg = /^([ +]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9 ]*)?$/
export const zipCodeFrReg = /^(?:[0-8]\d|9[0-8])\d{3}$/
export const domainReg = /(?=^.{2,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9])+$)/
export const fqdnReg = /^(?=^.{2,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)$/
export const trigramReg = /^[a-zA-Z]{3}$/
export const colorReg = /^#[a-f0-9]{6}$/
export const dateTimeReg = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}Z$/
