// ** React Imports
import React from "react"
// ** Store & Actions
// ** Third Party Components
import { Link, } from "react-router-dom"
import classNames from "classnames"
import { FormattedMessage, } from "react-intl"
import TemplateImg from "components/TemplateImg"
// ** Custom Components
// ** Hooks, context & utils
import { useLayout, } from "utility/context/Layout"
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images

interface TopOfferProps {
  className?: string
}

const TopOffer: React.FC<TopOfferProps> = ({ className, }) => {
  const { topOffer: { isVisible, hide, }, } = useLayout()

  if (!isVisible) return null

  return (
    <section className={classNames("top-offer", className)}>
      <div className="container text-center">
        <span
          onClick={hide}
          className="top-offer__dismiss sec-dismiss"
          data-section-classname="top-offer"
        >
          <i className="icon-close"></i>
        </span>
        <p>
          <i className="icon-stars"></i>
          <TemplateImg width={48} height={60} className="offer-img" />
          <FormattedMessage
            id="app.topOffer"
            values={{
              a: chunks => <Link to="/bonIpadAir.pdf" target="_blank"><span>{chunks}</span> <i className="icon-arrow"></i> </Link>,
            }}
          />
          <i className="icon-stars"></i>
        </p>
      </div>
    </section>
  )
}

export default TopOffer
