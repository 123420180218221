// ** React Imports
import React from "react"
// ** Store & Actions
// ** Third Party Components
import classNames from "classnames"
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images


interface CustomButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: React.ReactNode
  icon?: React.ReactNode
  color?: "primary" | "secondary" | "transparent-light" | "transparent-dark"
  hoverColor?: "light" | "dark" | "primary" | "secondary"
  textColor?: "light" | "dark"
  iconAlign?: "left" | "right"
  className?: string
}

const CustomButton: React.FC<CustomButtonProps> = ({ text, icon, color, hoverColor, textColor, iconAlign, className, ...rest }) => (
  <button
    className={classNames(
      "custom-btn",
      {
        "color-primary": color === "primary",
        "color-secondary": color === "secondary",
        "color-transparent-light": color === "transparent-light",
        "color-transparent-dark": color === "transparent-dark",
        "hover-color-light": hoverColor === "light",
        "hover-color-dark": hoverColor === "dark",
        "hover-color-primary": hoverColor === "primary",
        "hover-color-secondary": hoverColor === "secondary",
        "text-color-light": textColor === "light",
        "text-color-dark": textColor === "dark",
        "align-icon-left": iconAlign === "left",
      },
      className)}
    {...rest}
  >
    <span>
      {text}
    </span>
    {icon !== undefined && (
      <span className="icon">
        {icon}
      </span>
    )}
  </button>
)

export default CustomButton
