// ** React Imports
import { useState, useEffect, } from "react"
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Styles
// ** Images

type valueType = Record<string, unknown> | string | number | boolean | Array<Record<string, unknown> | string | number | boolean>

function getLocalStorageOrDefault<T extends valueType>(key: string, defaultValue?: T): T | undefined | null {
  const stored = localStorage.getItem(key)
  if (stored === null) {
    return defaultValue
  }
  try {
    return JSON.parse(stored) as T | null
  } catch (error) { // eslint-disable-line @typescript-eslint/no-unused-vars
    return defaultValue
  }
}

function useLocalStorage<T extends valueType = string>(_key: string): [ T | undefined, React.Dispatch<React.SetStateAction<T | undefined>>, () => void ]
function useLocalStorage<T extends valueType = string>(_key: string, _defaultValue: T): [ T, React.Dispatch<React.SetStateAction<T>>, () => void ]
function useLocalStorage<T extends valueType = string>(key: string, defaultValue?: T): [ T | undefined | null, React.Dispatch<React.SetStateAction<T | undefined | null>>, () => void ] {
  const [ value, setValue, ] = useState<T | undefined | null>(
    getLocalStorageOrDefault<T>(key, defaultValue)
  )

  const remove = (): void => {
    localStorage.removeItem(key)
    setValue(undefined)
  }

  useEffect(() => {
    if (value === undefined || value === null) remove()
    else localStorage.setItem(key, JSON.stringify(value))
  }, [ key, value, ])

  return [ value, setValue, remove, ]
}

export default useLocalStorage
