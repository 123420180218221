// ** React Imports
import React, { ReactNode, useEffect, } from "react"
// import React, { lazy, } from "react"
// ** Store & Actions
// ** Third Party Components
import { useLocation, Outlet, } from "react-router-dom"
// import { Outlet, useLocation, } from "react-router-dom"
// ** Custom Components
import HorizontalLayout from "layouts/Horizontal"
// ** Hooks, context & utils
import { useLayoutHandle, } from "./hooks"
// import { useAuth, } from "utility/context/Auth"
import { useApp, } from "utility/context/App"
import { useIntl, } from "utility/context/Internationalization"
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images

// lazy load login view
// const Login = lazy(async () => import("views/account/Login"))

// const checkAuthorisation = (user: User | null, allowedRoles?: Role): boolean => {
//   if (allowedRoles === undefined) return true
//   if (user === null) return false
//   // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
//   return Object.keys(allowedRoles).some(el => allowedRoles[el as keyof typeof allowedRoles] && user.role[el as keyof typeof allowedRoles])
// }

interface LayoutProps {
  children: ReactNode
}
const Layout: React.FC<LayoutProps> = ({ children, }) => {
  const { name, defaultPageTitle, } = useApp()
  const layoutHandle = useLayoutHandle()
  const { T, } = useIntl()

  document.title = layoutHandle.title !== undefined ? `${name} - ${T(layoutHandle.title)}` : defaultPageTitle

  if (layoutHandle.fullPage === true) return <>{children}</>

  // const { settings, } = useLayout()
  // const WrapperLayout = settings.layout.type === LayoutType.LAYOUT_HORIZONTAL ? HorizontalLayout : VerticalLayout
  // const WrapperLayout = HorizontalLayout

  return <HorizontalLayout>{children}</HorizontalLayout>
}


const LayoutWrapper: React.FC = () => {
  const location = useLocation()
  // const {  ReactGA, } = useApp()
  // const location = useLocation()
  // const { connectedUser, } = useAuth()
  // ReactGA.set({ page: location.pathname, }) // Update the user's current page
  // ReactGA.pageview(location.pathname) // Record a pageview for the given page


  useEffect(() => {
    window.scroll(0, 0)
  }, [ location, ])


  // if (layoutHandle.publicRoute !== true && connectedUser === null) {
  //   document.title = T("login")
  //   return <Login />
  // }

  return <Layout><Outlet /></Layout>
}
export default LayoutWrapper
