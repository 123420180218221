// ** React Imports
import { StrictMode, Suspense, } from "react"
// ** Store & Actions
// ** Third Party Components
import ReactDOM from "react-dom/client"
import { ErrorBoundary, } from "react-error-boundary"
import Modal from "react-bootstrap/Modal"
import Icon from "@mdi/react"
import { mdiWindowClose, } from "@mdi/js"
// ** Custom Components
import App from "./App"
import ErrorFallback from "components/ErrorBoundary"
import FallbackSpinner from "components/spinner/FallbackSpinner"
import ContactForm from "components/ContactForm"
// import LegalNotices from "components/LegalNotices"
// ** Hooks, context & utils
import { AppProvider, } from "utility/context/App"
import { AuthProvider, } from "utility/context/Auth"
import { IntlProvider, } from "utility/context/Internationalization"
import { LayoutProvider, useLayout, } from "utility/context/Layout"
import { NotificationProvider, } from "utility/context/Notification"
// ** Conf & helpers
// ** Objects
// ** Styles
import "animate.css"
import "assets/scss/style.scss"
// ** Images

import reportWebVitals from "./reportWebVitals"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

const ContactFormModal: React.FC = () => {
  const { contactLayer: { isVisible: contactFormVisible, hide: hideContactForm, }, } = useLayout()

  return (
    <Modal
      show={contactFormVisible}
      onHide={hideContactForm}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div
          className="close-btn"
          onClick={hideContactForm}
        >
          <span>
            <Icon path={mdiWindowClose} size={1} />
          </span>
        </div>
        <ContactForm title="contactUs" />
      </Modal.Body>
    </Modal>
  )
}

// const LegalNoticesModal: React.FC = () => {
//   const { legalNoticesLayer: { isVisible: legalNoticesLayerVisible, hide: hideLegalNoticesLayer, }, } = useLayout()

//   return (
//     <Modal
//       show={legalNoticesLayerVisible}
//       // onHide={() => { hideContactForm() }}
//       onHide={hideLegalNoticesLayer}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Body>
//         <div
//           className="close-btn"
//           onClick={hideLegalNoticesLayer}
//         >
//           <span>
//             <Icon path={mdiWindowClose} size={1} />
//           </span>
//         </div>
//         <LegalNotices />
//       </Modal.Body>
//     </Modal>
//   )
// }

root.render(
  <StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={<FallbackSpinner />}>
        <AppProvider>
          <AuthProvider>
            <IntlProvider>
              <LayoutProvider>
                <NotificationProvider>
                  <App />
                  <ContactFormModal />
                  {/* <LegalNoticesModal /> */}
                </NotificationProvider>
              </LayoutProvider>
            </IntlProvider>
          </AuthProvider>
        </AppProvider>
      </Suspense>
    </ErrorBoundary>
  </StrictMode>
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
